@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300");

.main {
  padding: 6rem 1rem 1rem;
}

h1 {
  display: inline;
}

.h1 {
  font-size: 1.2rem;
  font-weight: bold;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: "Poppins";
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  min-height: 75px;
  padding: 1rem;
  background-color: #ffdcba;
  color: black;
}

.navbar_links {
  display: flex;
}

.navbar_link {
  padding: 0 0.3rem;
  color: inherit;
}

.navbar_burger {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  color: inherit;
}

@media screen and (max-width: 767px) {
  .navbar_links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    visibility: hidden;
    right: -100vw;
    bottom: 0;
    width: 0;
    height: 100vh;
    padding: 2rem;
    background-color: #ffdcba;
    transition: all 0.8s ease-out;
  }

  .show-nav .navbar_links {
    right: 0;
    width: 80vw;
    visibility: visible;
  }

  .navbar_item::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 3vw;
    height: 1px;
    background: black;
  }
  .navbar_item:last-child::after {
    display: none;
  }

  .navbar_link {
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
  }

  .navbar_burger {
    display: block;
    position: fixed;
    top: 1rem;
    right: 1rem;
  }

  .navbar_burger:hover {
    cursor: pointer;
  }

  .burger-bar,
  .burger-bar::before,
  .burger-bar::after {
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: black;
    transition: all 0.5s ease-in-out;
  }

  .burger-bar::before,
  .burger-bar::after {
    content: "";
    position: absolute;
    left: 0;
  }

  .burger-bar::before {
    transform: translateY(-12px);
  }
  .burger-bar::after {
    transform: translateY(12px);
  }
  .show-nav .burger-bar {
    width: 0;
    background: transparent;
  }
  .show-nav .burger-bar::before {
    transform: rotate(45deg);
  }
  .show-nav .burger-bar::after {
    transform: rotate(-45deg);
  }
  .navbar_item {
    transform: translateY(100vh);
  }
  .show-nav .navbar_item {
    transform: translateY(0);
  }
  .show-nav .slideInDown-1 {
    transition: all 1s ease-out;
  }
  .show-nav .slideInDown-2 {
    transition: all 1.1s ease-out;
  }
  .show-nav .slideInDown-3 {
    transition: all 1.2s ease-out;
  }
  .show-nav .slideInDown-4 {
    transition: all 1.3s ease-out;
  }
  .show-nav .slideInDown-5 {
    transition: all 1.4s ease-out;
  }
  .show-nav .slideInDown-6 {
    transition: all 1.5s ease-out;
  }

  .tetr {
    text-align: center;
  }
}
