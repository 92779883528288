.main {
  min-height: calc(100vh - 50px);
}

footer {
  background-color: #ffdcba;
  display: flex;
  justify-content: space-between;

  div {
    margin: 0 2% 0 2%;
  }
}
.copyright {
  a {
    line-height: 50px;
  }
}

@media screen and (max-width: 767px) {
  footer {
    display: block;
    div {
      margin-top: 2%;
      margin-left: 20%;
    }
  }
}
