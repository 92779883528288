.main_accueil {
  max-width: 60vw;
  margin: auto;
}

.saviez_vous {
  text-align: center;
  margin: 1rem 0;
}
.saviez_vous_p {
  text-align: justify;
}

.rdv {
  color: black;
  font-weight: bold;
}
a {
  color: black;
}
.img_cabinet {
  width: 100%;
  padding: 4rem 0 0 0;
}

.formation {
  margin-top: 2%;
  display: flex;
  text-align: justify;

  div {
    margin: 0 2% 0 0;
  }
}
.image_osteo {
  display: flex;
}

.tests {
  margin: auto;
}

.vision {
  margin-top: 2%;
}
.avis {
  text-align: center;
  margin: 3% 0 2% 0;
  cursor: default;
}
.pointer {
  cursor: pointer;
}
.avis_actif {
  display: none;
}
.container_vision {
  padding-top: 3%;
}

@media screen and (max-width: 767px) {
  .main_accueil {
    max-width: 90%;
    margin: auto;
  }
  .formation {
    display: block;
  }
}
@media screen and (max-width: 400px) {
  .img_cabinet {
    height: 200px;
  }
}
