.main_tarifs {
  max-width: 60vw;
  margin: auto;
}

td {
  padding: 10px 50px 10px 0;
}

.tableau {
  margin: 0 auto;
  width: 500px;
  margin-top: 50px;
}

.mode_paiement_p {
  text-align: center;
  margin-bottom: 2%;
}

.mode_paiement {
  margin-top: 3%;
  border-bottom: 1px solid black;
}

.mutuelle {
  text-align: justify;
  margin: 2% 0;
}

.lien_mutuelle {
  margin-top: 1%;
}

.image_tarif {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2%;
}

.img_tarif {
  width: 10vw;
  margin: 5%;
}

@media screen and (max-width: 767px) {
  .main_tarifs {
    max-width: 100%;
    margin: auto;
  }
  .tableau {
    margin: 0 auto;
    width: 100%;
    margin-top: 20px;
  }
  .img_tarif {
    margin-top: 3%;
  }
}
